.btn-square {
  width: 90px;
  height: 85px;
  border: none;
  color: white;
  margin: 10px;
  border-radius: 2em;
  box-shadow: 0px 0px 10px 3px #060d18;
  box-shadow: none;
  outline: none;
  border: none;
}

.btn-telemedicina {
  background: #efefef;
}

.btn-categorias {
  background: white;
}
