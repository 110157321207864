.carousel-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.carousel-pagination .carousel-pagination-item {
  width: 20px; 
  height: 5px; 
  background-color: #E0E0E0;
  margin: 0 5px; 
  border-radius: 4px; 
  transition: background-color 0.3s ease;
}

.carousel-pagination .carousel-pagination-item.active {
  background-color: orange !important
}

.carousel-pagination-deslogado {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.carousel-pagination-deslogado .carousel-pagination-item {
  width: 20px; 
  height: 5px; 
  background-color: #529C94; 
  margin: 0 5px; 
  border-radius: 4px; 
  transition: background-color 0.3s ease;
}

.carousel-pagination-deslogado .carousel-pagination-item.desactive {
  background-color: #E0E0E0;
}
