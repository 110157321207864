.css-1ltezim[aria-selected=true], .css-1ltezim[data-selected]{
    color: black!important;
    border-color: black!important;
}
.css-a5mhaz {
    padding: 0.5em 0px!important;
}
.css-xumdn4{
    padding: 0.5em 0px!important;
}

.elemento-clicado {
    background-color: #C4C4C4; 
    width: Hug (52px);
    height: Hug (23px);
    left: 50px;
    padding: 3px  5px  3px  5px;
    border-radius: 5px;
    gap: 10px;
    font-size: 14px;    
}