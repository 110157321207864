.doorfloat{
	position:fixed;
	width:3.75rem;
	height:3.75rem;
	bottom:8.70rem;
	right:1.2rem;
	background-color:rgba(90, 178, 173, 0.7);
    
	color:#FFF;
	border-radius:3.125rem;
	text-align:center;
	box-shadow: 0.125rem 2px 3px #999;
	z-index: 1;
}